import React from 'react';
import Loader from 'cccisd-loader';
import axios from 'cccisd-axios';
import style from './style.css';
import _get from 'lodash/get';
import moment from 'moment';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

export default class ExportData extends React.Component {
    static propTypes = {};

    state = {
        loading: true,
    };

    componentDidMount = async () => {
        await this.getCsvFiles();

        await this.setState({ loading: false });
    };

    getCsvFiles = async () => {
        const result = await axios.get(Boilerplate.route('api.resources.file.privateIndex'));
        const csvfiles = _get(result.data, 'data');

        let bgcaCsv = csvfiles.filter(csv => {
            return csv.filename === 'bgca_spring2025.csv';
        })[0];

        let bgcaDupsCsv = csvfiles.filter(csv => {
            return csv.filename === 'bgca_spring2025_all.csv';
        })[0];

        let staffCsv = csvfiles.filter(csv => {
            return csv.filename === 'bgca_staff_spring2025.csv';
        })[0];

        await this.setState({
            bgcaCsv,
            bgcaDupsCsv,
            staffCsv,
        });
    };

    render() {
        if (this.state.loading) {
            return <Loader loading={this.state.loading} />;
        }

        const { bgcaCsv, bgcaDupsCsv, staffCsv } = this.state;

        return (
            <div className="container">
                <h1>Export Data</h1>

                <div className={style.block}>
                    <div className={style.blockLeft}>
                        <div className={style.buck} />
                    </div>

                    <div className={style.kat} />
                    <div className={style.ollie} />
                </div>
                <div className={style.row}>
                    <div className={style.column}>
                        <h4>Member Survey</h4>
                        <div className={style.exportButtons}>
                            <a
                                href={Boilerplate.url('api/resources/file/private/bgca/spring2025/bgca_spring2025.csv')}
                                rel="noopener noreferrer"
                            >
                                <button
                                    type="button"
                                    className={`btn btn-primary btn-md ${style.csvButton}`}
                                    disabled={bgcaCsv ? '' : 'disabled'}
                                >
                                    Member Survey Data
                                </button>
                            </a>
                            <h5>
                                <em>
                                    <>
                                        Last updated:{' '}
                                        {bgcaCsv
                                            ? moment
                                                  .utc(bgcaCsv.updated_at)
                                                  .tz('America/New_York')
                                                  .format('MM/DD/YYYY HH:mm')
                                            : ''}
                                    </>
                                </em>
                            </h5>
                        </div>
                    </div>

                    <div className={style.column}>
                        <h4>Duplicate Member Survey</h4>
                        <div className={style.exportButtons}>
                            <a
                                href={Boilerplate.url(
                                    'api/resources/file/private/bgca/spring2025/bgca_spring2025_all.csv'
                                )}
                                rel="noopener noreferrer"
                            >
                                <button
                                    type="button"
                                    className={`btn btn-primary btn-md ${style.csvButton}`}
                                    disabled={bgcaDupsCsv ? '' : 'disabled'}
                                >
                                    Duplicate Member Survey Data
                                </button>
                            </a>
                            <h5>
                                <em>
                                    <>
                                        Last updated:{' '}
                                        {bgcaDupsCsv
                                            ? moment
                                                  .utc(bgcaDupsCsv.updated_at)
                                                  .tz('America/New_York')
                                                  .format('MM/DD/YYYY HH:mm')
                                            : ''}
                                    </>
                                </em>
                            </h5>
                        </div>
                    </div>

                    <div className={style.column}>
                        <h4>Staff Survey</h4>
                        <div className={style.exportButtons}>
                            <a
                                href={Boilerplate.url(
                                    'api/resources/file/private/bgca/spring2025/bgca_staff_spring2025.csv'
                                )}
                                rel="noopener noreferrer"
                            >
                                <button
                                    type="button"
                                    className={`btn btn-primary btn-md ${style.csvButton}`}
                                    disabled={staffCsv ? '' : 'disabled'}
                                >
                                    Staff Survey Data
                                </button>
                            </a>
                            <h5>
                                <em>
                                    <>
                                        Last updated:{' '}
                                        {staffCsv
                                            ? moment
                                                  .utc(staffCsv.updated_at)
                                                  .tz('America/New_York')
                                                  .format('MM/DD/YYYY HH:mm')
                                            : ''}
                                    </>
                                </em>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
